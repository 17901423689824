import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { VIEW_TYPE_PIPES } from 'accounts/constants';

/**
 * @typedef {object} AuthenticatedUser
 * @property {number} id
 * @property {string} email
 * @property {string} name
 * @property {boolean} debug_mode_enabled
 * @property {string[]} groups
 * @property {string[]} permissions
 * @property {string[]} access_permissions
 */

/**
 * @typedef {object} DJConst DJ_CONST value sent from the server. It includes
 * other variables as well, but `open_view` is the most important.
 * @property {VIEW_TYPE_PIPES|VIEW_TYPE_PARTS} open_view
 * @property {AuthenticatedUser} [user]
 * @property {number} [BODY_CUTTING_ACTION_ID]
 * @property {number} [BODY_EXPANSION_ACTION_ID]
 * @property {object} [FILTERS]
 *
 * @property {object} [auto_logout] Defines auto logout behavior
 * @property {boolean} auto_logout.AUTO_LOGOUT_ENABLED
 * @property {number} auto_logout.AUTO_LOGOUT_TIMEOUT
 * @property {number} auto_logout.AUTO_LOGOUT_PROMPT_TIMEOUT
 *
 * @property {object} [config]
 * @property {boolean} config.PIPE_COOLING_ACTIVE
 */

/** @type {DJConst} */
const initialState = { open_view: VIEW_TYPE_PIPES };

export const DJConstContext = createContext(initialState);

/**
 * Provide the DJ_CONST value to its children via context. Meant to be consumed
 * using the `useDJConst` hook. This helps with making components easier to
 * test and less dependant on global variables.
 * @param {{
 *  djConst: DJConst,
 *  children: React.ReactNode,
 * }} props
 */
export const DJConstProvider = ({ djConst, children }) => (
    <DJConstContext.Provider value={djConst}>
        {children}
    </DJConstContext.Provider>
);

DJConstProvider.propTypes = {
    djConst: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

/**
 * In some cases a class component already has one context. E.g. PipesCalendar
 * contains the context required for drag-n-drop functionality and we can't
 * replace it by DJConstContext.
 * It makes sense to get DJ_CONST value from the Consumer and pass it to the
 * components as a prop.
 * It uses style that allows to use the HOC with redux compose function, see
 * src/pipes/timeplan/components/calendar/PipesCalendar.js for refs.
 */
export const withDJConst = () => (WrappedComponent) => (props) => (
    <DJConstContext.Consumer>
        {(djConst) => <WrappedComponent djConst={djConst} {...props} />}
    </DJConstContext.Consumer>
);

export const useDJConst = () => useContext(DJConstContext);
